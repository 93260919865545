export default [
  {
    title: 'Main',
    route: 'main',
  },
  {
    title: 'Links',
    route: 'links',
  },
  {
    title: 'Files',
    route: 'files',
  },
]
